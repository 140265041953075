import { navigate } from "@reach/router"
import React, { useContext, useState } from "react"
import styled from "styled-components"
import { BackendContext } from "../services"

const RootDiv = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`

const LoginForm = styled.form`
    border: 1px solid #3f3f3f;
    border-radius: 10px;
    padding: 40px;
    display: flex;
    flex-direction: column;
`

const StyledInput = styled.input`
    padding: 10px;
    border-radius: 5px;
    font-size: ${props => props.theme.textSize};
    margin-top: 20px;
    border: 1px solid ${props => props.theme.textColor};
    &:focus {
        box-shadow: none;
        shadow: none;
        outline: none;
    }
`

const StyledLabel = styled.label`
    font-size: ${props => props.theme.subHeaderSize};
    color: ${props => props.theme.textColor};
`

const StyledButton = styled.button`
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
    background: ${props => props.theme.main};
    color: white;
    cursor: pointer;
    border: none;
    font-size: ${props => props.theme.textSize};
`

const Login = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const { login } = useContext(BackendContext)

    const onSubmit = (email: string, password: string) => {
        const onAuthStateChanged = signedIn => {
            if (signedIn) navigate("/admin")
            else navigate("/admin/login")
        }
        login(
            email,
            password,
            onAuthStateChanged).catch(console.error)
    }

    return (
        <RootDiv>
            <LoginForm onSubmit={e => {
                e.preventDefault()
                onSubmit(email, password)
            }}>
                <StyledLabel>Anmelden</StyledLabel>
                <StyledInput
                    type="email"
                    name="username"
                    placeholder="Benutzername"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
                <StyledInput
                    type="password"
                    name="password"
                    value={password}
                    placeholder="Passwort"
                    onChange={e => setPassword(e.target.value)}
                />
                <StyledButton
                    type="submit"
                >Anmelden</StyledButton>

            </LoginForm>
        </RootDiv>
    )
}

export default Login
