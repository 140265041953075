import React, { useContext, useState } from "react"
import styled from "styled-components"
import JobOffering from "../types/jobOffering"
import Text, { TextType } from "./text"
import { DeleteButton, EditButton } from "./editButtons"
import Anforderungen from "../icons/anforderungen.svg"
import DasBietenWir from "../icons/das-bieten-wir.svg"
import DasErwartenWir from "../icons/das-erwarten-wir.svg"
import Aufgaben from "../icons/aufgaben.svg"
import Tiles from "./tiles"
import { BackendContext } from "../services"
import JobOfferingPopup from "./jobOfferingPopup"

const RootDiv = styled.div`
    display: flex;
    flex-direction: column;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
`

const StyledUl = styled.ul`
    padding: 10px;
`

const StyledLi = styled.li`
    font-size: 12px;
`

const NameRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const iconCss = `
    height: 30px;
    width: 30px;
    margin-right: 20px;
`

const AnforderungenIcon = styled(Anforderungen)`
    ${iconCss}
    `
const DasBietenWirIcon = styled(DasBietenWir)`
   ${iconCss}
`
const DasErwartenWirIcon = styled(DasErwartenWir)`
    ${iconCss}
`
const AufgabenIcon = styled(Aufgaben)`
    ${iconCss}
`

const getBodyUl = (lines: string[]) => (
    <StyledUl>
        {lines.map((line, index) => (
            <StyledLi key={index}>
                <Text type={TextType.Body}>
                    {line}
                </Text>
            </StyledLi>))}
    </StyledUl>
)



const getTile = (offering: JobOffering) => {
    const tileList = [
        {
            title: "Aufgaben",
            icon: <AufgabenIcon />,
            body: getBodyUl(offering.aufgaben),
        },
        {
            title: "Anforderungen",
            icon: <AnforderungenIcon />,
            body: getBodyUl(offering.anforderungen),
        },
        {
            title: "Das erwarten wir",
            icon: <DasErwartenWirIcon />,
            body: getBodyUl(offering.dasErwartenWir)
        },
        {
            title: "Das bieten wir",
            icon: <DasBietenWirIcon />,
            body: getBodyUl(offering.dasBietenWir)
        }
    ]
    return (<Tiles tileList={tileList} />)
}



const AdminJobOffering = ({ jobOffering, key }: { jobOffering: JobOffering, key: number }) => {

    const { deleteJobOffering } = useContext(BackendContext)

    const [showJobEditPopup, setShowJobEditPopup] = useState(false)

    const onDelete = () => {
        if (typeof jobOffering.id === 'string' && jobOffering.id !== 'undefined') {
            deleteJobOffering(jobOffering.id)
        }
    }

    const onEdit = () => setShowJobEditPopup(true)

    return (
        <RootDiv key={key}>
            <NameRow>
                <Text type={TextType.SubHeader}>{jobOffering.name}</Text>
                <Row>
                    <EditButton size="20px" onClick={onEdit} />
                    <DeleteButton size="20px" onClick={onDelete} />
                </Row>
            </NameRow>
            {getTile(jobOffering)}
            {showJobEditPopup && <JobOfferingPopup onClose={() => setShowJobEditPopup(false)} initial={jobOffering} />}
        </RootDiv>
    )
}

export default AdminJobOffering
