import React from "react"
import styled from "styled-components"
import { FiCheck, FiEdit, FiMinus, FiPlus, FiTrash, FiX } from "react-icons/fi"

const StyledButton = styled.div`
    border: none;
    margin: 10px;
    cursor: pointer;
`

export const AddButton = ({ size, onClick }: { size: string, onClick: () => void }) => (
    <StyledButton onClick={onClick}>
        <FiPlus size={size} />
    </StyledButton>
)

export const DeleteButton = ({ size, onClick }: { size: string, onClick: () => void }) => (
    <StyledButton onClick={onClick}>
        <FiTrash size={size} />
    </StyledButton>
)

export const EditButton = ({ size, onClick }: { size: string, onClick: () => void }) => (
    <StyledButton onClick={onClick}>
        <FiEdit size={size} />
    </StyledButton>
)

export const OkButton = ({ size, onClick }: { size: string, onClick: () => void }) => (
    <StyledButton onClick={onClick}>
        <FiCheck size={size} />
    </StyledButton>
)

export const AbortButton = ({ size, onClick }: { size: string, onClick: () => void }) => (
    <StyledButton onClick={onClick}>
        <FiX size={size} />
    </StyledButton>
)

export const MinusButton = ({ size, onClick }: { size: string, onClick: () => void }) => (
    <StyledButton onClick={onClick}>
        <FiMinus size={size} />
    </StyledButton>
)
