import { deleteObject, getStorage, ref } from "firebase/storage"
import React, { useContext, useState } from "react"
import styled from "styled-components"
import useJobOfferingCollection from "../hooks/jobOfferingCollection"
import { BackendContext } from "../services"
import Popup from "./popup"
import Text, { TextType } from "./text"

const RootDiv = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${props => props.theme.textColor};
    opacity: 1;
    background: white;
`

export enum DeleteType {
    Offering = "offering",
    Document = "document"
}


const StyledButton = styled.button`
    border: none;
    margin: 10px;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    color: white;
    background: ${props => props.red ? "red" : "green"};
`

const ButtonDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-self: right;
`

const DeletePopup = ({ type, onClose, deleteName, deleteId, onDeleted }: { type: DeleteType, onClose: () => void, deleteName: string, deleteId: string, onDeleted: () => void }) => {

    const [deletionError, setDeletionError] = useState(false)

    const { deleteDocument, deleteJobOffering } = useContext(BackendContext)


    const onDelete = () => {

        if (type === DeleteType.Document) {
            deleteDocument(deleteId).then(onDeleted).catch(() => setDeletionError(true))
        }
        else {
            deleteJobOffering(deleteId).then(onDeleted).catch(() => setDeletionError(true))
        }
    }

    return (
        <Popup onClick={onClose}>
            <RootDiv onClick={e => e.stopPropagation()}>
                {deletionError ?
                    <>
                        <Text type={TextType.SubHeader}>Löschen nicht möglich (Verbindungsproblemen, ...). </Text>
                        <ButtonDiv>
                            <StyledButton red onClick={onClose}>Schliessen</StyledButton>
                        </ButtonDiv>
                    </>
                    :
                    <>
                        <Text type={TextType.SubHeader}>
                            {`${type === "document" ? "Dokument" : "Stellenangebot"} ${deleteName} wirklich löschen?`}
                        </Text>
                        <ButtonDiv>
                            <StyledButton onClick={onDelete}>Ja</StyledButton>
                            <StyledButton red onClick={onClose}>Nein</StyledButton>
                        </ButtonDiv>
                    </>
                }
            </RootDiv>
        </Popup >

    )
}

export default DeletePopup
