import React, { useCallback, useContext, useState } from "react"
import styled from "styled-components"
import { BackendContext } from "../services"
import Popup from "./popup"
import Text, { TextType } from "./text"
import { useDropzone } from "react-dropzone"
import Loader from "react-loader-spinner"

const DropZone = styled.label`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed ${props => props.error ? "red" : props.theme.textColor};
    border-radius: 5px;
    input {
        display: none;
    }
    margin-bottom: 20px;
    cursor: pointer;
    height: 200px;
    padding: 20px;
`

const RootDiv = styled.div`
    display: flex;
    width: 1200px;
    flex-direction: column;
    background: white;
    border-radius: 10px;
    border: 1px solid ${props => props.theme.textColor};
    padding: 10px;
    opacity: 1;
    overflow: scroll;
`

const HeaderTextDiv = styled.div`
    margin-bottom: 50px;
`

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
`

const FileNameInput = styled.input`
    font-size: ${props => props.theme.textSize};
    color: ${props => props.theme.textColor};
    border: 1px solid ${props => props.error ? "red" : props.theme.textColor};
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    &:focus {
        box-shadow: none;
        shadow: none;
        outline: none;
    }
`

const SubmitButton = styled.button`
    align-self: right;
    padding: 10px;
    background: ${props => props.ready ? props.theme.main : props.theme.second};
    border-radius: 5px;
    border: none;
    color: ${props => props.ready ? "white" : props.theme.textColor};
    cursor: pointer;
`

const StyledLoader = styled(Loader)`
    align-self: center;
    justify-self: center;
    margin: 20px;
`


const UploadFilePopup = ({ onUploadFinished }: { onUploadFinished: () => void }) => {

    const { addDocument } = useContext(BackendContext)

    const [file, setFile] = useState<File | null>(null)
    const [fileName, setFileName] = useState("")
    const [fileNameError, setFileNameError] = useState(false)
    const [fileError, setFileError] = useState(false)
    const [isUploading, setIsUploading] = useState(false)

    const onDrop = useCallback(files => {
        if (files[0]) {
            setFile(files[0])
        }
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    const onSubmit = (e) => {
        e.preventDefault()
        const fileNameNotSet = fileName.length <= 0
        setFileNameError(fileNameNotSet)
        setFileError(!file)
        if (!fileNameNotSet && file) {
            addDocument(fileName, file).then(onUploadFinished).catch(console.error)
            setIsUploading(true)
        }
    }

    return (
        <Popup onClick={() => onUploadFinished()}>
            <RootDiv onClick={e => e.stopPropagation()}>
                <HeaderTextDiv>
                    <Text type={TextType.SubHeader}>
                        Neues Dokument Hochladen
                    </Text>
                </HeaderTextDiv>
                {isUploading ?
                    <StyledLoader
                        type="ThreeDots"
                        color="#4d6888"
                    />
                    :
                    <StyledForm onSubmit={onSubmit}>
                        <FileNameInput
                            type="text"
                            name="fileName"
                            placeholder="Dateiname"
                            value={fileName}
                            onChange={e => setFileName(e.target.value)}
                            error={fileNameError && fileName.length <= 0}
                        />
                        <DropZone error={fileError && !file} {...getRootProps()} >
                            <Text type={TextType.Body}>
                                {isDragActive ?
                                    "Datei hier loslassen" :
                                    file ? `Ausgewählte Datei: ${file.name}`
                                        : "Datei per Click oder Drag'n'Drop auswählen"}
                            </Text>
                            <input {...getInputProps()} />
                        </DropZone>
                        <SubmitButton type="submit" ready={fileName.length > 0 && file} >Hochladen</SubmitButton>
                    </StyledForm>
                }
            </RootDiv>
        </Popup>
    )
}

export default UploadFilePopup
