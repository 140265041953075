import React from "react"
import styled from "styled-components"

const RootDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(55,55,55,0.6);
    z-index: 3;
`

const Popup = ({ children, onClick }: { children: React.ReactNode, onClick: () => void }) => (
    <RootDiv onClick={onClick}>
        {children}
    </RootDiv>
)

export default Popup
