import React, { useContext } from "react"
import { ThemeProvider } from "styled-components"
import { BackendContext } from "../services"
import { navigate, Router } from "@reach/router"
import Admin from "../components/admin"
import Login from "../components/login"
import Theme from "../theme"
import loadable from '@loadable/component'


const AdminWrapper = ({ component: Component, signedIn, ...rest }) => {
    if (!signedIn) {
        navigate("/admin/login")
        return null
    } else
        return <Component {...rest} />
}

const LoginWrapper = ({ component: Component, signedIn, ...rest }) => {
    if (signedIn) {
        navigate("/admin")
        return null
    } else
        return <Component {...rest} />
}

const RouterWrapper = () => {

    const { signedIn } = useContext(BackendContext)

    return (
        <Router basepath="/admin">
            <AdminWrapper path="/" component={Admin} signedIn={signedIn} />
            <LoginWrapper path="/login" component={Login} signedIn={signedIn} />
        </Router>
    )
}


const BackendProvider = loadable(() => import("../services/backend/backend-provider"))


const AdminPage = () => {

    return (
        <BackendProvider>
            <ThemeProvider theme={Theme({ phone: false })}>
                <RouterWrapper />
            </ThemeProvider>
        </BackendProvider>
    )
}

export default AdminPage
