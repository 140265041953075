import React, { useContext, useState } from "react"
import { useEffect } from "react"
import styled from "styled-components"
import { BackendContext } from "../services"
import JobOffering from "../types/jobOffering"
import { DeleteButton, AddButton, EditButton, OkButton, AbortButton, MinusButton } from "./editButtons"
import Popup from "./popup"
import Text, { TextType } from "./text"


const RootDiv = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 10px;
    border: 1px solid ${props => props.theme.textColor};
    padding: 10px;
    opacity: 1;
    z-index: 5;
`

const HeaderTextDiv = styled.div`
    margin-bottom: 20px;
`
const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
`
const AddTextDiv = styled.div`
    display: flex;
    flex-direction: row;
`

const StyledTextArea = styled.textarea`
    display: block;
    resize: none;
    white-space: pre-wrap;
    font-size: ${props => props.theme.textSize};
    border-radius: 5px;
    padding: 0;
    border: 1px solid ${props => props.theme.textColor};
`

const StyledInput = styled.input`
    border: 1px solid ${props => props.error ? "red" : props.theme.textColor};
    border-radius: 5px;
    font-size: ${props => props.theme.textSize};
`

const StyledLabel = styled.label`
    margin-top: 20px;
    margin-bottom: 5px;
    font-size: ${props => props.theme.subHeaderSize};
`

const StyledUl = styled.ul`
    list-style: none;
    padding: 0;
`

const StyledLi = styled.li`
    display: flex;
    flex-direction: row;
`

const AddTextRow = styled.div`
    display: flex;
    flex-direction: row;
`

const SubmitButton = styled.button`
    font-size: ${props => props.theme.textSize};
`

const TextField = ({ placeholder, value, onChange }: { placeholder: string, value: string, onChange: (newVal: string) => void }) => {
    const [numRows, setNumRows] = useState(0)
    const numCols = 30

    const growRows = (newVal: string) => {
        const numRows = newVal.split("\n").map(line => line.length < 1 ? 1 : 1 + Math.floor((line.length - 1) / (numCols + 1))).reduce((a, b) => a + b, 0)
        setNumRows(numRows)
    }
    useEffect(() => growRows(value), [value])

    return (
        <StyledTextArea
            placeholder={placeholder}
            value={value}
            onChange={e => {
                onChange(e.target.value)
                growRows(e.target.value)
            }}
            rows={numRows}
            cols={numCols}
        />

    )
}

const TextListItem = ({ key, text, onTextChanged, onDelete }) => {
    const [currText, setCurrText] = useState(text)
    const [edit, setEdit] = useState(false)

    return (
        edit ?
            (
                <StyledLi key={key}>
                    <TextField
                        onChange={setCurrText}
                        placeholder="Text editieren"
                        value={currText}
                    />
                    <OkButton size="20px" onClick={() => {
                        onTextChanged(currText)
                        setEdit(false)
                    }} />
                    <AbortButton size="20px" onClick={() => {
                        setCurrText(text)
                        setEdit(false)
                    }} />
                </StyledLi >)

            :
            (<StyledLi key={key}><Text type={TextType.Body}>{text}</Text>
                <EditButton onClick={() => setEdit(true)} size="20px" />
                <DeleteButton onClick={() => onDelete()} size="20px" /></StyledLi>)
    )

}

const TextList = ({ name, list, onAdd, onDelete, onEdit }) => {

    const [newText, setNewText] = useState("")

    return (
        <>
            <StyledLabel>{name}</StyledLabel>
            <AddTextRow>
                <AddTextDiv>
                    <TextField
                        placeholder="Neuer Unterpunkt"
                        value={newText}
                        onChange={setNewText}
                    />
                </AddTextDiv>
                <AddButton size="20px" onClick={() => {
                    onAdd(newText)
                    setNewText("")
                }} />
            </AddTextRow>
            <StyledUl>
                {list.map((elem, i) =>
                    <TextListItem
                        key={i}
                        text={elem}
                        onTextChanged={newText => onEdit(i, newText)}
                        onDelete={() => onDelete(i)}
                    />)}
            </StyledUl>
        </>
    )
}

const SizeSelectionDiv = styled.div`
    display: flex;
    flex-direction: row;
`

const IncreaseRow = styled.div`
    display: flex;
    flex-direction: row;
`

const BoxSizeSelection = ({ onIncrease, onDecrease }: { onIncrease: () => void, onDecrease: () => void }) => (
    <>
        <StyledLabel>
            Größe der ausgeklappten Box
        </StyledLabel>
        <SizeSelectionDiv>
            <IncreaseRow>
                <Text type={TextType.Body} >
                    Bitte so lange erhöhen bis Box auf normalem displaz nicht mehr abgeschnitten ist.
                </Text>
                <AddButton size="20px" onClick={onIncrease} />
                <MinusButton size="20px" onClick={onDecrease} />
            </IncreaseRow>
        </SizeSelectionDiv>
    </>
)

const JobOfferingPopup = ({ onClose, initial }: { onClose: () => void, initial?: JobOffering }) => {

    const [submitError, setSubmitError] = useState(false)
    const [nameError, setNameError] = useState(false)
    const [aufgabenError, setAufgabenError] = useState(false)
    const [anforderungenError, setAnforderungenError] = useState(false)
    const [dasBietenWirError, setDasBietenWirError] = useState(false)
    const [dasErwartenWirError, setDasErwartenWirError] = useState(false)
    const emptyOffering: JobOffering = {
        name: "",
        aufgaben: [],
        anforderungen: [],
        dasBietenWir: [],
        dasErwartenWir: [],
        maxHeightPhone: 1000,
        maxHeightTablet: 750,
        maxHeightLarge: 500,
    }

    const deleteListElem = (i, list) => [...list.slice(0, i), ...list.slice(i + 1)]
    const editListElem = (i, newElem, list) => [list.slice(0, i), newElem, ...list.slice(i + 1)]
    const [currentOffering, setCurrentOffering] = useState<JobOffering>(initial ? initial : emptyOffering)
    const [removedOffering, setRemovedOffering] = useState<JobOffering>(emptyOffering)

    const onBoxSizeIncrease = () => {
        setCurrentOffering({ ...currentOffering, maxHeightPhone: currentOffering.maxHeightPhone + 200, maxHeightTablet: currentOffering.maxHeightTablet + 150, maxHeightLarge: currentOffering.maxHeightLarge + 100 })
    }

    const onBoxSizeDecrease = () => {
        setCurrentOffering({ ...currentOffering, maxHeightPhone: currentOffering.maxHeightPhone - 200, maxHeightTablet: currentOffering.maxHeightTablet - 150, maxHeightLarge: currentOffering.maxHeightLarge - 100 })
    }

    const onAufgabenAdd = text => setCurrentOffering({ ...currentOffering, aufgaben: [text, ...currentOffering.aufgaben], })

    const onAufgabenDelete = i => {
        if (initial) {
            if (currentOffering.aufgaben[i] !== 'undefined') {
                const deletedElem = currentOffering.aufgaben[i]
                setRemovedOffering({ ...removedOffering, aufgaben: [deletedElem, ...removedOffering.aufgaben] })
            }
        }
        setCurrentOffering(currentOffering => ({ ...currentOffering, aufgaben: deleteListElem(i, currentOffering.aufgaben) }))
    }

    const onAufgabenEdit = (i, newText) =>
        setCurrentOffering({ ...currentOffering, aufgaben: editListElem(i, newText, currentOffering.aufgaben) })

    const onAnforderungAdd = text =>
        setCurrentOffering({ ...currentOffering, anforderungen: [text, ...currentOffering.anforderungen] })

    const onAnforderungDelete = i => {
        if (initial) {
            if (currentOffering.anforderungen[i] !== 'undefined') {
                const deletedElem = currentOffering.anforderungen[i]
                setRemovedOffering({ ...removedOffering, anforderungen: [deletedElem, ...removedOffering.anforderungen] })
            }
        }
        setCurrentOffering({ ...currentOffering, anforderungen: deleteListElem(i, currentOffering.anforderungen) })
    }

    const onAnforderungEdit = (i, newText) =>
        setCurrentOffering({ ...currentOffering, anforderungen: editListElem(i, newText, currentOffering.anforderungen) })

    const onDasBietenWirAdd = text =>
        setCurrentOffering({ ...currentOffering, dasBietenWir: [text, ...currentOffering.dasBietenWir] })

    const onDasBietenWirDelete = i => {
        if (initial) {
            if (currentOffering.dasBietenWir[i] !== 'undefined') {
                const deletedElem = currentOffering.dasBietenWir[i]
                setRemovedOffering({ ...removedOffering, dasBietenWir: [deletedElem, ...removedOffering.dasBietenWir] })
            }
        }
        setCurrentOffering({ ...currentOffering, dasBietenWir: deleteListElem(i, currentOffering.dasBietenWir) })
    }

    const onDasBietenWirEdit = (i, newText) =>
        setCurrentOffering({ ...currentOffering, dasBietenWir: editListElem(i, newText, currentOffering.dasBietenWir) })

    const onDasErwartenWirAdd = text =>
        setCurrentOffering({ ...currentOffering, dasErwartenWir: [text, ...currentOffering.dasErwartenWir] })

    const onDasErwartenWirDelete = i => {
        if (initial) {
            if (currentOffering.dasErwartenWir[i] !== 'undefined') {
                const deletedElem = currentOffering.dasErwartenWir[i]
                setRemovedOffering({ ...removedOffering, dasErwartenWir: [deletedElem, ...removedOffering.dasErwartenWir] })
            }
        }
        setCurrentOffering({ ...currentOffering, dasErwartenWir: deleteListElem(i, currentOffering.dasErwartenWir) })
    }

    const onDasErwartenWirEdit = (i, newText) =>
        setCurrentOffering({ ...currentOffering, dasErwartenWir: editListElem(i, newText, currentOffering.dasErwartenWir) })

    const { addJobOffering, editJobOffering } = useContext(BackendContext)

    const onSubmit = (e) => {
        e.preventDefault()
        if (currentOffering.name === "") {
            setNameError(true)
        }
        if (currentOffering.aufgaben.length === 0) {
            setAufgabenError(true)
        }
        if (currentOffering.anforderungen.length === 0) {
            setAnforderungenError(true)
        }
        if (currentOffering.dasBietenWir.length === 0) {
            setDasBietenWirError(true)
        }
        if (currentOffering.dasErwartenWir.length === 0) {
            setDasErwartenWirError(true)
        }
        else {
            if (initial) editJobOffering(currentOffering, removedOffering).catch(err => setSubmitError(true))
            else addJobOffering(currentOffering).then(onClose).catch(err => setSubmitError(true))
            onClose()
        }
    }

    return (

        <Popup onClick={onClose}>
            <RootDiv onClick={e => e.stopPropagation()}>
                <HeaderTextDiv>
                    <Text type={TextType.SubHeader}>
                        Jobangebot erstellen
                    </Text>
                </HeaderTextDiv>
                {submitError ? <p>
                    <Text type={TextType.SubHeader}>Fehler beim Hochladen (Verbindugsprobleme, ...).</Text></p>
                    :
                    <StyledForm onSubmit={onSubmit}>
                        <StyledLabel>Name</StyledLabel>
                        <AddTextDiv>
                            <StyledInput
                                placeholder="Jobangebotsname"
                                type="text"
                                value={currentOffering.name}
                                onChange={e => setCurrentOffering({ ...currentOffering, name: e.target.value })}
                                error={nameError}
                                cols={30}
                            />
                        </AddTextDiv>
                        <TextList
                            name="Aufgaben"
                            onAdd={onAufgabenAdd}
                            onDelete={onAufgabenDelete}
                            list={currentOffering.aufgaben}
                            onEdit={onAufgabenEdit}
                        />
                        <TextList
                            name="Anforderungen"
                            onAdd={onAnforderungAdd}
                            onDelete={onAnforderungDelete}
                            list={currentOffering.anforderungen}
                            onEdit={onAnforderungEdit}
                        />
                        <TextList
                            name="Das bieten wir"
                            onAdd={onDasBietenWirAdd}
                            onDelete={onDasBietenWirDelete}
                            list={currentOffering.dasBietenWir}
                            onEdit={onDasBietenWirEdit}
                        />
                        <TextList
                            name="Das erwarten wir"
                            onAdd={onDasErwartenWirAdd}
                            onDelete={onDasErwartenWirDelete}
                            list={currentOffering.dasErwartenWir}
                            onEdit={onDasErwartenWirEdit}
                        />
                        <BoxSizeSelection onIncrease={onBoxSizeIncrease} onDecrease={onBoxSizeDecrease} />
                        <SubmitButton type="submit" >Hochladen</SubmitButton>
                    </StyledForm>}
            </RootDiv>
        </Popup>
    )
}

export default JobOfferingPopup
