import { getStorage, listAll, ref } from "firebase/storage"
import React, { useContext, useEffect, useState } from "react"
import { FiPlus } from "react-icons/fi"
import styled from "styled-components"
import { BackendContext } from "../services"
import AdminFile from "./adminFile"
import AdminJobOffering from "./adminJobOffering"
import ContentDiv from "./contentDiv"
import JobOfferingPopup from "./jobOfferingPopup"
import Text, { TextType } from "./text"
import UploadFilePopup from "./uploadFilePopup"

const RootDiv = styled.div`
  margin: auto;
  width: 100%;
  @media (min-width: 1200px) {
      max-width: 1200px;
      width: 1200px;
  }

`

const HeaderDiv = styled.div`
    margin-top: 100px;
    text-align: center;
`

const OptionHeaderDiv = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    min-width: 100%;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 20px;
`

const OptionHeader = ({ hoverText, children, onClick }: { hoverText: string, children: React.ReactNode, onClick: () => void }) => {

    return (
        <OptionHeaderDiv>
            <Text type={TextType.SubHeader} >
                {children}
            </Text>
            <FiPlus size="30px" onClick={onClick} style={{ cursor: "pointer" }} />
        </OptionHeaderDiv>)
}


const DocumentsSection = styled.div`
    display: flex;
    flex-direction: column;
`

const OfferingsSection = styled.div`
    display: flex;
    flex-direction: column;
`

const Admin = () => {

    const [showDocAddPopup, setShowDocAddPopup] = useState(false)
    const [showJobAddPopup, setShowJobAddPopup] = useState(false)
    const [listVer, setListVer] = useState(0)
    const incListVer = () => setListVer(listVer + 1)
    const { documents, jobOfferings } = useContext(BackendContext)

    return (
        <RootDiv>
            <HeaderDiv>
                <Text type={TextType.Header}>
                    Admin Bereich
                </Text>
            </HeaderDiv>
            <OptionHeader hoverText="Dokument hinzufügen" onClick={() => setShowDocAddPopup(true)} >
                Download Dokumente
            </OptionHeader>
            <DocumentsSection>
                {documents.map((file, i) => <AdminFile key={i} fileName={file} onFileDeleted={incListVer} />)}
            </DocumentsSection>
            <OptionHeader hoverText="Jobangebot hinzufügen" onClick={() => setShowJobAddPopup(true)} >
                Jobangebote
            </OptionHeader>
            <OfferingsSection>
                {jobOfferings.map((offering, i) => <AdminJobOffering key={i} jobOffering={offering} />)}
            </OfferingsSection>
            {showDocAddPopup && <UploadFilePopup onUploadFinished={() => {
                incListVer()
                setShowDocAddPopup(false)
            }} />}
            {showJobAddPopup && <JobOfferingPopup onClose={() => setShowJobAddPopup(false)} />}
        </RootDiv>
    )
}


export default Admin
