import React, { useContext, useState } from "react"
import styled from "styled-components"
import Text, { TextType } from "./text"
import { BackendContext } from "../services"
import DeletePopup, { DeleteType } from "./deletePopup"
import { DeleteButton } from "./editButtons"


const RootDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid grey;
    padding; 10px;
    width: 100%;
`

const AdminFile = ({ fileName, onFileDeleted, key }: { fileName: string, onFileDeleted: () => void, key: number }) => {

    const { deleteDocument } = useContext(BackendContext)

    const [showDeletePopup, setShowDeletePopup] = useState(false)

    const onDelete = () => {
        deleteDocument(fileName).then(onFileDeleted).catch(console.error)
    }

    return (
        <RootDiv key={key}>
            <Text type={TextType.Body}>
                {fileName}
            </Text>
            <DeleteButton onClick={onDelete} size="20px" />
            {showDeletePopup && <DeletePopup
                onClose={() => setShowDeletePopup(false)}
                onDeleted={() => setShowDeletePopup(false)}
                deleteName={fileName}
                deleteId={fileName}
                type={DeleteType.Document}
            />}
        </RootDiv>
    )
}

export default AdminFile
